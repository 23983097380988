exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-pages-404-js": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/pages/404.js" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-pages-404-js" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-category-js-content-file-path-contents-categories-it-it-iso-9001-iso-9001-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-category.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/categories/it_IT/iso-9001/iso-9001.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-category-js-content-file-path-contents-categories-it-it-iso-9001-iso-9001-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-homepage-js-content-file-path-contents-pages-it-it-homepage-homepage-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-page-homepage.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/pages/it_IT/homepage/homepage.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-homepage-js-content-file-path-contents-pages-it-it-homepage-homepage-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-chi-siamo-chi-siamo-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-page.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/pages/it_IT/chi-siamo/chi-siamo.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-chi-siamo-chi-siamo-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-contatti-contatti-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-page.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/pages/it_IT/contatti/contatti.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-contatti-contatti-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-privacy-e-cookie-policy-privacy-e-cookie-policy-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-page.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/pages/it_IT/privacy-e-cookie-policy/privacy-e-cookie-policy.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-privacy-e-cookie-policy-privacy-e-cookie-policy-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-termini-e-condizioni-termini-e-condizioni-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-page.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/pages/it_IT/termini-e-condizioni/termini-e-condizioni.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-termini-e-condizioni-termini-e-condizioni-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-come-prepararsi-per-un-audit-iso-9001-come-prepararsi-per-un-audit-iso-9001-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-post.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/posts/it_IT/come-prepararsi-per-un-audit-iso-9001/come-prepararsi-per-un-audit-iso-9001.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-come-prepararsi-per-un-audit-iso-9001-come-prepararsi-per-un-audit-iso-9001-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-i-costi-della-iso-9001-i-costi-della-iso-9001-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-post.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/posts/it_IT/i-costi-della-iso-9001/i-costi-della-iso-9001.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-i-costi-della-iso-9001-i-costi-della-iso-9001-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-il-miglioramento-continuo-nella-iso-9001-il-miglioramento-continuo-nella-iso-9001-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-post.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/posts/it_IT/il-miglioramento-continuo-nella-iso-9001/il-miglioramento-continuo-nella-iso-9001.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-il-miglioramento-continuo-nella-iso-9001-il-miglioramento-continuo-nella-iso-9001-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-integrazione-dipendenti-iso-9001-integrazione-dipendenti-iso-9001-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-post.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/posts/it_IT/integrazione-dipendenti-iso-9001/integrazione-dipendenti-iso-9001.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-post-js-content-file-path-contents-posts-it-it-integrazione-dipendenti-iso-9001-integrazione-dipendenti-iso-9001-md" */),
  "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-tag-js-content-file-path-contents-tags-it-it-innovazione-il-mio-primo-tag-md": () => import("./../../../node_modules/@bufox/gatsby-theme-dry-mdx/src/templates/mdx-tag.js?__contentFilePath=/home/simone/Code/bufox-sites/fidatissimi.it/contents/tags/it_IT/innovazione/il-mio-primo-tag.md" /* webpackChunkName: "component---node-modules-bufox-gatsby-theme-dry-mdx-src-templates-mdx-tag-js-content-file-path-contents-tags-it-it-innovazione-il-mio-primo-tag-md" */)
}

